import { TableCell, TableRow } from '@mui/material';
import Container from '../../UI/Common/Container';
import _ from 'lodash';
import { IAuditLog } from '../../../@types/AuditLog';

interface Props {
    auditLog: IAuditLog;
}

export default function AuditLogsTableItem({ auditLog }: Props) {
    const DIVIDE_BY_NANOSECONDS = 1000000;

    const timestamp = parseInt(auditLog.timestamp) / DIVIDE_BY_NANOSECONDS;

    function formatDate(date) {
        const year = _.padStart(date.getFullYear(), 4, '0');
        const month = _.padStart(date.getMonth() + 1, 2, '0');
        const day = _.padStart(date.getDate(), 2, '0');
        const hours = _.padStart(date.getHours(), 2, '0');
        const minutes = _.padStart(date.getMinutes(), 2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    <Container>{formatDate(new Date(timestamp))}</Container>
                </TableCell>
                <TableCell>{auditLog.user}</TableCell>
                <TableCell>{auditLog.event}</TableCell>
            </TableRow>
        </>
    );
}
