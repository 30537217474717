import { ChevronLeft } from '@mui/icons-material';
import { SxProps } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Flex from 'styled-flex-component';
import { IFeedback } from '../../../@types/Feedback';
import { ITagItem } from '../../../@types/Tag';
import { provideFeedback } from '../../../api/feedback';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../pages/Client/GetInsights/context/getInsightsContext';
import { Theme } from '../../../theme';
import { AddDocAttachmentButton } from '../../AddDocAttachmentButton/AddDocAttachmentButton';
import AddTagModal from '../../Documents/AddTagModal';
import Container from '../../UI/Common/Container';
import DocumentsTypeSelector from '../../UI/DocumentTypeSelector.tsx/DocumentTypeSelector';
import TagDisplay from '../../UI/TagDisplay';
import { Text } from '../../UI/Typography';
import ClauseNavigationBar from '../ClauseNavigationBar';
import KeyDataNavigationBar from '../KeyDataNavigationBar';

interface IGetInsightsHeaderProps {
    setNewDocType: (docType: string) => void;
}

const GetInsightsHeader = ({ setNewDocType }: IGetInsightsHeaderProps) => {
    const { documentData, isDocumentLoaded } = useContext(
        GetInsightsContext
    ) as GetInsightsContextType;
    const [selectedDocumentType, setSelectDocumentType] = useState<string>(documentData.type);
    const navigate = useNavigate();

    const [selectedTags, setSelectedTags] = useState<string[]>(Object.keys(documentData.tags));
    const [isAddTagOpen, setIsAddTagOpen] = useState(false);

    const updateTable = (tags: ITagItem[], action) => {
        tags.forEach((tag) => {
            if (action == 'add') documentData.tags[tag.name] = tag.color;
            else if (action == 'delete') delete documentData.tags[tag.name];
        });
        setSelectedTags(Object.keys(documentData.tags));
    };

    const onDocTypeChange = (selectedType: string) => {
        setSelectDocumentType(selectedType);
        setNewDocType(selectedType);
        const feedback: IFeedback[] = [
            {
                correct: false,
                key: 'type',
                idx: 0,
                field: 'type',
                gt: selectedType,
            },
        ];
        provideFeedback(documentData.id, feedback);
    };

    const handleUploadSuccess = (result) => {
        const newDocId = result.entries[0].sha1;
        navigate(`/client/insights/${newDocId}`);
        window.location.reload();
    };

    const handleUploadError = () => {
        alert('Failed to upload annex!');
    };

    return (
        <Container fixed width="100%" zIndex={1} backgroundColor={Theme.primaryDark}>
            <Container
                leftInnerSpacing={2}
                rightInnerSpacing={2.6875}
                topInnerSpacing={0.75}
                bottomInnerSpacing={0.75}
                color={Theme.whiteColor}
            >
                <Flex justifyBetween alignCenter>
                    <Flex alignCenter>
                        <Container
                            rightOuterSpacing={0.75}
                            topOuterSpacing={0.4}
                            cursor="pointer"
                            onClick={() => {
                                window.history.state && window.history.state.idx > 0
                                    ? navigate(-1)
                                    : navigate('/client/documents');
                                localStorage.setItem('currentMatchIndex', '0');
                            }}
                        >
                            <ChevronLeft style={{ color: Theme.whiteColor }} />
                        </Container>
                        <Container maxWidth={30}>
                            <Text
                                customFontWeight={600}
                                data-testid="header-document-name"
                                customFontSize={1}
                                color={Theme.whiteColor}
                                whiteSpace="break-spaces"
                                wordBreak="break-all"
                            >
                                {documentData.name}
                            </Text>
                        </Container>
                        <Container leftOuterSpacing={1}>
                            <DocumentsTypeSelector
                                selectedType={selectedDocumentType}
                                setSelectedType={onDocTypeChange}
                                customStyles={
                                    {
                                        'background': 'rgba(255, 255, 255, 0.08)',
                                        'paddingLeft': '10px',
                                        'paddingRight': '10px',
                                        'paddingTop': '7px',
                                        'paddingBottom': '7px',
                                        'borderRadius': '100px',
                                        'fontSize': '13px',
                                        'color': Theme.whiteColor,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: Theme.whiteColor,
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: Theme.whiteColor,
                                        },
                                    } as SxProps
                                }
                            />
                        </Container>
                        {isDocumentLoaded && (
                            <>
                                <TagDisplay
                                    documentData={documentData}
                                    selectedTags={selectedTags}
                                    setIsAddTagOpen={setIsAddTagOpen}
                                />

                                <div style={{ marginLeft: '15px' }}>
                                    <AddDocAttachmentButton
                                        type="default"
                                        fileId={documentData.id}
                                        onUploadSuccess={handleUploadSuccess}
                                        onUploadError={handleUploadError}
                                    />
                                </div>
                            </>
                        )}
                        <AddTagModal
                            isOpen={isAddTagOpen}
                            setIsOpen={() => setIsAddTagOpen(!isAddTagOpen)}
                            onSaveTagsSuccess={(tags, action) => {
                                setIsAddTagOpen(false);
                                updateTable(tags, action);
                            }}
                            documentIds={[documentData.id]}
                            documentTags={documentData.tags}
                            mode={'updating'}
                        />
                    </Flex>
                    <Container leftOuterSpacing={0.5}>
                        <ClauseNavigationBar />
                        <KeyDataNavigationBar />
                    </Container>
                </Flex>
            </Container>
        </Container>
    );
};
export default GetInsightsHeader;
