import { IAuditLog } from '../@types/AuditLog';
import apiClient from './apiClient';

interface IAuditLogEndpointResponse {
    logs: IAuditLog[];
    next_timestamp: number;
}

export const getAuditLogsByUser = async (startDate?: string, endDate?: string, cursor?: number) => {
    return apiClient
        .get<IAuditLogEndpointResponse>(`/auditlogs/`, {
            params: {
                start_date: startDate?.length ? startDate : undefined,
                end_date: endDate?.length ? endDate : undefined,
                next_timestamp: cursor,
                limit: 10,
            },
        })
        .then((res) => res.data);
};
