import { Badge, Box, Button, Tooltip } from '@mui/material';
import Flex from 'styled-flex-component';
import { MeliorTranslate } from '../../MeliorTranslate';
import { Text } from '../../UI/Typography';
import { Theme } from '../../../theme';
import { DeleteOutline } from '@mui/icons-material';
import HiddenInsightsBadge from '../../UI/Badge/HiddenInsightsBadge';
import { useContext } from 'react';
import {
    InsightsTemplateContext,
    InsightsTemplateContextType,
} from '../../../pages/Client/InsightsTemplate/context/insightsTemplateContext';
import { useTranslation } from 'react-i18next';

interface ITemplateViewProps {
    setMode: (mode: string) => void;
    setIsOpen: (isOpen: boolean) => void;
    setIsDeleting: (isDeleting: boolean) => void;
}

export default function TemplateView({ setMode, setIsOpen, setIsDeleting }: ITemplateViewProps) {
    const { isSearching, templates, selectedTemplate, hiddenClauses } = useContext(
        InsightsTemplateContext
    ) as InsightsTemplateContextType;
    const { t } = useTranslation();

    return (
        <div
            style={{
                height: '80vh',
                borderRadius: '14px',
                background: 'white',
            }}
        >
            {/* Template Detail View */}
            {!(isSearching && !templates.length) && (
                <Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            gap: '10px',
                            padding: '20px',
                        }}
                    >
                        {/* Share */}
                        <Button variant="contained" disabled={true}>
                            <Text uppercase color={Theme.whiteColor} customletterSpacing={0.05}>
                                <MeliorTranslate valueKey="Share" />
                            </Text>
                        </Button>
                        {/* Edit */}
                        <Button
                            variant="contained"
                            onClick={() => {
                                setMode('edit');
                                setIsOpen(true);
                            }}
                        >
                            <Text
                                uppercase
                                // color={Theme.whiteColor}
                                customletterSpacing={0.05}
                            >
                                <MeliorTranslate valueKey="Edit" />
                            </Text>
                        </Button>
                        {/* Delete */}
                        <Tooltip title={t('Delete')}>
                            <Button
                                variant="text"
                                onClick={() => {
                                    setIsDeleting(true);
                                }}
                            >
                                <DeleteOutline />
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Flex
                        column
                        justifyCenter
                        style={{ gap: '25px', padding: '30px', paddingTop: 0 }}
                    >
                        <Text
                            style={{
                                height: '6vh',
                                overflowY: 'auto',
                                marginTop: '10px',
                            }}
                        >
                            {templates[selectedTemplate].template_description}
                        </Text>
                        <Flex
                            style={{
                                height: '28vh',
                                gap: '20px',
                                width: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            <div style={{ width: '50%' }}>
                                {/* Label & Count */}
                                <Flex alignCenter>
                                    <Text customFontWeight={600}>
                                        <MeliorTranslate valueKey="Displayed Insights" />
                                    </Text>
                                    <Badge
                                        badgeContent={templates[selectedTemplate].visible.length}
                                        sx={{ margin: 2 }}
                                        color="primary"
                                    />
                                </Flex>
                                <Flex
                                    column
                                    style={{
                                        height: '24vh',
                                        overflowY: 'auto',
                                        gap: '5px',
                                        marginTop: '5px',
                                        background: 'whitesmoke',
                                    }}
                                >
                                    {templates[selectedTemplate].visible.map((clause, index) => (
                                        <Flex
                                            alignCenter
                                            justifyBetween
                                            style={{
                                                minHeight: '50px',
                                                border: '1px solid lightgray',
                                                borderRadius: '5px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                background: 'white',
                                            }}
                                            key={index}
                                        >
                                            <Text color={Theme.primaryDark}>{clause}</Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            </div>
                            <div style={{ width: '50%' }}>
                                {/* Label & Count */}
                                <Flex alignCenter style={{ margin: 10, marginLeft: 0 }}>
                                    <Text customFontWeight={600} style={{ marginRight: 16 }}>
                                        <MeliorTranslate valueKey="Hidden Insights" />
                                    </Text>
                                    <HiddenInsightsBadge content={hiddenClauses.length} />
                                </Flex>
                                <Flex
                                    column
                                    style={{
                                        height: '24vh',
                                        overflowY: 'auto',
                                        gap: '5px',
                                        marginTop: '5px',
                                        background: 'whitesmoke',
                                    }}
                                >
                                    {hiddenClauses.map((clause, index) => (
                                        <Flex
                                            alignCenter
                                            justifyBetween
                                            style={{
                                                minHeight: '50px',
                                                border: '1px solid lightgray',
                                                borderRadius: '5px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                background: 'white',
                                            }}
                                            key={index}
                                        >
                                            <Text color={Theme.primaryDark}>{clause}</Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            </div>
                        </Flex>
                        <div
                            style={{
                                height: '28vh',
                                width: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            {/* Label & Count */}
                            <Flex alignCenter>
                                <Text customFontWeight={600}>
                                    <MeliorTranslate valueKey="Document Types" />
                                </Text>
                                <Badge
                                    badgeContent={
                                        templates[selectedTemplate].linked_doc_types.length
                                    }
                                    sx={{ margin: 2 }}
                                    color="primary"
                                />
                            </Flex>
                            <Flex
                                column
                                style={{
                                    height: '24vh',
                                    overflowY: 'auto',
                                    gap: '5px',
                                    marginTop: '5px',
                                    background: 'whitesmoke',
                                }}
                            >
                                {templates[selectedTemplate].linked_doc_types.map((type, index) => (
                                    <Flex
                                        alignCenter
                                        justifyBetween
                                        style={{
                                            minHeight: '50px',
                                            border: '1px solid lightgray',
                                            borderRadius: '5px',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                            background: 'white',
                                        }}
                                        key={index}
                                    >
                                        <Text color={Theme.primaryDark}>{type}</Text>
                                    </Flex>
                                ))}
                            </Flex>
                        </div>
                    </Flex>
                </Box>
            )}
        </div>
    );
}
