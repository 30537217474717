import { Button, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import Container from '../../../UI/Common/Container';
import { Text } from '../../../UI/Typography';
import Flex from 'styled-flex-component';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { EnumWorkflowNodeType, IWorkflow, IWorkflowNode } from '../../../../@types/Workflow';
import { toast } from 'react-toastify';
import WorkflowLink from '../../../UI/WorkflowLink';
import { useState } from 'react';
import DeleteWorkflowModal from '../../Modals/DeleteWorkflowModal';
import DocumentTypeChip from '../../../UI/DocumentTypeChip/DocumentTypeChip';
import { MeliorTranslate } from '../../../MeliorTranslate';
import { useTranslation } from 'react-i18next';

interface IWorkflowsTableItemProps {
    workflow: IWorkflow;
    index: number;
    onDeleteSuccess: () => void;
}

export default function WorkflowsTableItem({
    workflow,
    index,
    onDeleteSuccess,
}: IWorkflowsTableItemProps) {
    const { t } = useTranslation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    async function launch() {
        try {
            const webhookNodes = workflow.nodes.filter(
                (node) => node.type === EnumWorkflowNodeType.WEBHOOK
            );

            Promise.all(
                webhookNodes.map((webhookNode) => {
                    return fetch(`https://n8n.melior.ai/webhook/${webhookNode.webhookId}`)
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            return data;
                        });
                })
            )
                .then(() => {
                    toast.success('Workflow was started');
                })
                .catch(console.error.bind(console));
        } catch (e) {
            console.log('e', e);
        }
    }

    const hasWebhookNode = workflow.nodes.some(
        (node: IWorkflowNode) => node.type === EnumWorkflowNodeType.WEBHOOK
    );

    return (
        <>
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    <Container>
                        <WorkflowLink workflow={workflow} />
                    </Container>
                </TableCell>
                <TableCell>
                    <Flex>
                        {workflow.tags.map((tag, index) => (
                            <Container rightOuterSpacing={0.3} key={index}>
                                <DocumentTypeChip label={tag.name} />
                            </Container>
                        ))}
                    </Flex>
                </TableCell>
                <TableCell>
                    <Button variant="outlined" disabled={!hasWebhookNode} onClick={launch}>
                        <Text>
                            <MeliorTranslate valueKey="Launch" />
                        </Text>
                    </Button>
                </TableCell>
                <TableCell>
                    <Tooltip title={t('Edit')}>
                        <IconButton
                            href={`https://n8n.melior.ai/workflow/${workflow.id}`}
                            target="_blank"
                            color="inherit"
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title={t('Delete')}>
                        <IconButton color="inherit" onClick={() => setShowDeleteDialog(true)}>
                            <DeleteOutline />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <DeleteWorkflowModal
                open={showDeleteDialog}
                workflow={workflow}
                onClose={() => {
                    setShowDeleteDialog(false);
                }}
                onDeleteSuccess={() => {
                    setShowDeleteDialog(false);
                    onDeleteSuccess();
                }}
            />
        </>
    );
}
