export interface IDocument {
    id: string;
    name: string;
    status?: string;
    type: string;
    clauses: ClauseData;
    dynamic_insights?: DynamicInsightItem;
    entities: EntityType;
    box_details?: StorageDetails;
    storage_details?: StorageDetails;
    tags: object;
}

export interface DynamicInsightItem {
    [key: string]: DynamicInsightsData;
}

export interface ClauseData {
    [key: string]: ClauseDataExtraction[];
}

export interface DynamicInsightsData {
    collection_id: string;
    collection_name: string;
    answers: DynamicInsightsDataAnswer[];
}

export interface DynamicInsightsDataAnswer {
    answer: string;
    bboxes: number[][][];
    pages: number[];
    question: DynamicInsightsAnswer;
}

export interface DynamicInsightsAnswer {
    alias: string;
    question: string;
    question_id: string;
    question_type: string;
}

export interface ClauseDataExtraction {
    _predicted?: {
        answer: string;
        score: number;
        page: number;
        bboxes: number[][];
        end: number | null;
        start: number | null;
    };
    page: number;
    answer: string;
    bboxes?: number[][];
    value?: string;
}

export interface EntityType {
    [key: string]: EntityData[];
}

export interface EntityData {
    text: string;
    detections: EntityDetection[];
}

export interface EntityDetection {
    text: string;
    page: number;
    value: string | number | null;
    unit: string | null;
    bbox?: number[][];
}

export interface StorageDetails {
    file_id: string;
    user_id: string;
}

export interface DocumentAction {
    name: string;
    function: any;
}

export enum DocumentSortType {
    UPLOAD_TIMESTAMP = '_upload_timestamp',
    TYPE = 'type',
    FILE_NAME = 'name',
    STATUS = 'status',
}

export enum SortOrder {
    DESCENDING = -1,
    ASCENDING = 1,
}
