import { createTheme, ThemeProvider } from '@mui/material';
import { Theme } from '../../theme';

const theme = createTheme({
    palette: {
        primary: {
            main: Theme.whiteColor,
        },
    },
});

export default function DocViewThemeWrapper(props) {
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
