import {
    Badge,
    Box,
    Button,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import Flex from 'styled-flex-component';
import { Text } from '../../../UI/Typography';
import { MeliorTranslate } from '../../../MeliorTranslate';
import { isEmpty } from 'lodash';
import { ArrowDropDown, PlaylistAddCheck } from '@mui/icons-material';
import { getDynamicInsightsClauses } from '../../../../utils/getClauses';
import { useContext } from 'react';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../../pages/Client/GetInsights/context/getInsightsContext';
import { Theme } from '../../../../theme';
import SelectSubheader from '../SelectSubheader';
import { useTranslation } from 'react-i18next';
import { ITemplate } from '../../../../@types/Template';
import { createTemplate } from '../../../../api/template.api';
import { toast } from 'react-toastify';
import { IDisplayClause } from '../../../../@types/Comparison';
import { useClauseLibrary } from '../../../../hooks/useClauseLibrary';

interface IClausesListHeaderProps {
    hasChanges: boolean;
    docType: string;
    openSelect: boolean;
    setHasChanges: (val: boolean) => void;
    setOpenSelect: (val: boolean) => void;
    setOpenCreate: (val: boolean) => void;
    setInitialClauses: (val: IDisplayClause[]) => void;
    applyTemplate: (val: any) => void;
}

const ClausesListHeader = ({
    hasChanges,
    docType,
    openSelect,
    applyTemplate,
    setHasChanges,
    setOpenSelect,
    setOpenCreate,
    setInitialClauses,
}: IClausesListHeaderProps) => {
    const { t } = useTranslation();
    const { clauseLibrary } = useClauseLibrary();
    const {
        templates,
        documentData,
        isFetchingClauses,
        displayedClauses,
        currTemplate,
        setCurrTemplate,
        setTemplates,
    } = useContext(GetInsightsContext) as GetInsightsContextType;

    const handleSave = async () => {
        try {
            let templateToSave: ITemplate =
                templates.find((template) => template.template_name == currTemplate) ??
                ({} as ITemplate);
            templateToSave = {
                ...templateToSave,
                linked_doc_types: templateToSave.linked_doc_types
                    .filter((type) => type != docType)
                    .concat(docType),
                visible: displayedClauses.map((clause) => clause.key),
            };

            await createTemplate(templateToSave).then((res) => {
                if (res) {
                    setHasChanges(false);
                    setInitialClauses(displayedClauses.concat([]));
                    toast.success(t('Template saved successfully.'));
                    setTemplates(
                        templates
                            .filter((saved) => saved.template_name != templateToSave.template_name)
                            .concat(templateToSave)
                    );
                }
            });
        } catch (e) {
            toast.error(t('Error saving template.'));
        }
    };

    return (
        <Box
            sx={{
                borderBottom: 1,
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 2,
                paddingRight: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Flex alignCenter>
                <Text customFontSize={0.9} style={{ marginRight: '20px' }}>
                    <MeliorTranslate valueKey="Insight List" />
                </Text>
                {!isFetchingClauses && (
                    <Badge badgeContent={displayedClauses.length} color="primary" />
                )}
            </Flex>
            <Flex style={{ height: '40px' }}>
                {hasChanges && displayedClauses.length > 0 && (
                    <Button
                        style={{ flex: 'none' }}
                        variant="text"
                        onClick={() =>
                            currTemplate && currTemplate != 'None'
                                ? handleSave()
                                : setOpenCreate(true)
                        }
                        size="small"
                    >
                        <MeliorTranslate
                            valueKey={
                                currTemplate && currTemplate != 'None' ? 'Save' : 'Create List'
                            }
                        />
                    </Button>
                )}
                {!isEmpty(templates) && (
                    <FormControl fullWidth>
                        <IconButton
                            onClick={() => setOpenSelect(!openSelect)}
                            disableRipple={true}
                            sx={{ '&:hover': { color: Theme.highlight } }}
                        >
                            <Tooltip
                                title={
                                    currTemplate && currTemplate != 'None'
                                        ? `${currTemplate} ${t('template has been applied')}`
                                        : t('Apply a template')
                                }
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '1px solid',
                                        borderColor: Theme.primary,
                                        borderRadius: '30px',
                                        padding: 3,
                                        color: Theme.primary,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}
                                >
                                    <PlaylistAddCheck />
                                    {currTemplate && currTemplate != 'None' && (
                                        <Flex alignCenter>
                                            <Text
                                                style={{
                                                    marginLeft: '10px',
                                                    width: '80px',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflowX: 'hidden',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {currTemplate}
                                            </Text>
                                            <ArrowDropDown />
                                        </Flex>
                                    )}
                                </div>
                            </Tooltip>
                            <Select
                                fullWidth
                                onChange={(e) => {
                                    setCurrTemplate(e.target.value);

                                    const template =
                                        e.target.value == 'None'
                                            ? getDynamicInsightsClauses(documentData).concat(
                                                  clauseLibrary.map((clause) => clause.name)
                                              )
                                            : templates.find(
                                                  (temp) => temp.template_name == e.target.value
                                              )?.visible;

                                    applyTemplate(template);
                                }}
                                value={currTemplate}
                                open={openSelect}
                                style={{ visibility: 'hidden', width: 0, height: 0 }}
                            >
                                {/* None */}
                                {currTemplate && currTemplate != 'None' && (
                                    <MenuItem value={'None'}>{'None'}</MenuItem>
                                )}

                                {/* Group 1 - Linked by Document Type */}
                                {templates.find((template) =>
                                    template.linked_doc_types.includes(docType)
                                ) && (
                                    <SelectSubheader>
                                        {docType} <MeliorTranslate valueKey="Templates" />{' '}
                                    </SelectSubheader>
                                )}
                                {templates.find((template) =>
                                    template.linked_doc_types.includes(docType)
                                ) &&
                                    templates
                                        .filter((template) =>
                                            template.linked_doc_types.includes(docType)
                                        )
                                        .map((template, index) => (
                                            <MenuItem value={template.template_name} key={index}>
                                                <Text>{template.template_name}</Text>
                                            </MenuItem>
                                        ))}

                                {/* Group 2 - Others */}
                                {templates.find(
                                    (template) => !template.linked_doc_types.includes(docType)
                                ) && (
                                    <SelectSubheader>
                                        {t(
                                            templates.find((template) =>
                                                template.linked_doc_types.includes(docType)
                                            )
                                                ? 'Other Templates'
                                                : 'Templates'
                                        )}
                                    </SelectSubheader>
                                )}
                                {templates
                                    .filter(
                                        (template) => !template.linked_doc_types.includes(docType)
                                    )
                                    .map((template, index) => (
                                        <MenuItem value={template.template_name} key={index}>
                                            <Text>{template.template_name}</Text>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </IconButton>
                    </FormControl>
                )}
            </Flex>
        </Box>
    );
};

export default ClausesListHeader;
