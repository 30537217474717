import { DocumentSortType, SortOrder } from '../../@types/Document';
import Container from '../UI/Common/Container';
import Flex from 'styled-flex-component';
import { Text } from '../UI/Typography';
import { MeliorTranslate } from '../MeliorTranslate';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

interface ISorterProps {
    sortOrder: SortOrder;
    sortBy: DocumentSortType;
    sortName: DocumentSortType;
    displayName: string;
    setSortBy: (sortBy: DocumentSortType) => void;
    setSortOrder: (sortOrder: SortOrder) => void;
}

export default function Sorter({
    sortName,
    sortOrder,
    sortBy,
    displayName,
    setSortBy,
    setSortOrder,
}: ISorterProps) {
    return (
        <Container
            cursor="pointer"
            onClick={() => {
                setSortBy(sortName);
                setSortOrder(
                    sortOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING
                );
            }}
        >
            <Flex>
                <Text customFontWeight={700}>
                    <MeliorTranslate valueKey={displayName} />
                </Text>

                {sortBy === sortName && (
                    <>
                        {sortOrder === SortOrder.ASCENDING && <ArrowDropUp />}
                        {sortOrder === SortOrder.DESCENDING && <ArrowDropDown />}
                    </>
                )}
            </Flex>
        </Container>
    );
}
