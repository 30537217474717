import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandLess, KeyboardArrowRight } from '@mui/icons-material';
import Flex from 'styled-flex-component';
import { Text } from '../../../UI/Typography';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import HiddenInsightsBadge from '../../../UI/Badge/HiddenInsightsBadge';
import { Theme } from '../../../../theme';
import ClausesListItem from '../ClausesListItem';
import Container from '../../../UI/Common/Container';
import { useContext } from 'react';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../../pages/Client/GetInsights/context/getInsightsContext';

interface OtherInsightsProps {
    sidebarSize: number;
    isExpanded: boolean;
    setIsExpanded: (val: boolean) => void;
    removeInsight: (e, clause, index) => void;
    editSelection: (e, clause) => void;
    displayInsight: (clause, index) => void;
}

const OtherInsights = ({
    sidebarSize,
    isExpanded,
    setIsExpanded,
    removeInsight,
    editSelection,
    displayInsight,
}: OtherInsightsProps) => {
    const { hiddenClauses } = useContext(GetInsightsContext) as GetInsightsContextType;
    return (
        <Accordion
            elevation={5}
            sx={{
                bottom: 0,
                minWidth: `${sidebarSize - 0.2}%`,
                maxHeight: '50vh',
                display: hiddenClauses.length ? 'block' : 'none',
                position: 'relative',
            }}
            expanded={isExpanded}
            onChange={(e, expanded) => {
                setIsExpanded(expanded);
            }}
        >
            <AccordionSummary
                expandIcon={isExpanded ? <ExpandLess /> : <KeyboardArrowRight />}
                style={{
                    height: '75px',
                    borderBottom: hiddenClauses.length ? '1px solid rgba(13, 24, 50, 0.12)' : '',
                    width: '100%',
                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Flex alignCenter>
                    <Text color={Theme.primaryDark} style={{ marginRight: '20px' }}>
                        Other Insights
                    </Text>
                    <HiddenInsightsBadge content={hiddenClauses.length} />
                </Flex>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    overflowY: 'auto',
                    height: '40vh',
                    padding: 0,
                }}
            >
                <Droppable droppableId="hidden">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {Boolean(hiddenClauses.length) &&
                                hiddenClauses.map((clause, index) => (
                                    <Container key={clause.id}>
                                        <Draggable draggableId={clause.id} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <ClausesListItem
                                                        index={index}
                                                        clause={clause}
                                                        removeInsight={removeInsight}
                                                        displayInsight={displayInsight}
                                                        isHideable={false}
                                                        editSelection={editSelection}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    </Container>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </AccordionDetails>
        </Accordion>
    );
};

export default OtherInsights;
