import { useTranslation } from 'react-i18next';
import Container from '../../../UI/Common/Container';
import Empty from '../../../UI/Common/Empty';
import { useContext } from 'react';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../../pages/Client/GetInsights/context/getInsightsContext';

interface IClausesLoadingPlaceholderProps {
    clauses: string[];
}

const ClausesLoadingPlaceholder = ({ clauses }: IClausesLoadingPlaceholderProps) => {
    const { t } = useTranslation();
    const { isFetchingClauses } = useContext(GetInsightsContext) as GetInsightsContextType;
    return (
        <>
            {!Boolean(clauses.length) && (
                <Container outerSpacing={2}>
                    <Empty message="There are no clauses for this document" />
                </Container>
            )}
            {Boolean(isFetchingClauses) && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'full',
                        marginTop: 50,
                    }}
                >
                    <Empty message={t('Fetching insights...')} />
                </div>
            )}
        </>
    );
};

export default ClausesLoadingPlaceholder;
