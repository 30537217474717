import { NotificationsOutlined } from '@mui/icons-material';
import { Badge, IconButton, LinearProgress, Menu, MenuItem, Typography } from '@mui/material';
import { Theme } from '../../theme';
import { useState } from 'react';
import useWebsocket from '../../hooks/useWebsocket';
import { getUserSessionData } from '../../utils/userSession';
import { isEmpty } from 'lodash';

interface DynamicInsightMessage {
    status: string;
    message: string;
    progress: number;
}

export default function GlobalNotificationsButton() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loadingDocs, setLoadingDocs] = useState({});
    const user = getUserSessionData();

    const parseMessage = (topic: string, message: object) => {
        const documentId = topic.split('/')[2];
        setLoadingDocs((prev) => ({
            ...prev,
            [documentId]: message as DynamicInsightMessage,
        }));
    };

    useWebsocket(
        {
            clientId: 'global-notifs',
            topics: [`${user?.tenantId}/document/+/updates`],
        },
        {
            onMessage: parseMessage,
        }
    );

    return (
        <>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Badge badgeContent={Object.keys(loadingDocs).length} color="primary">
                    <NotificationsOutlined sx={{ fontSize: 20, color: Theme.primaryDark }} />
                </Badge>
            </IconButton>

            <Menu
                style={{ width: '450px' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {isEmpty(loadingDocs) && (
                    <MenuItem
                        style={{
                            padding: '10px 20px',
                            fontStyle: 'italic',
                            background: '#f5f5f5',
                        }}
                    >
                        <Typography variant="body2">No running processes.</Typography>
                    </MenuItem>
                )}

                {Object.keys(loadingDocs).map((docId) => (
                    <MenuItem key={docId}>
                        <div style={{ width: '100%' }}>
                            <Typography variant="body2">{docId}</Typography>
                            <LinearProgress
                                variant="determinate"
                                value={loadingDocs[docId].progress}
                            />
                            <div
                                style={{
                                    width: '20rem',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Typography color="textSecondary" variant="caption" noWrap>
                                    {loadingDocs[docId].progress}% - {loadingDocs[docId].message}
                                </Typography>
                            </div>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
