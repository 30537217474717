import { DynamicInsightsDataAnswer } from '../@types/Document';
import { DYNAMIC_INSIGHT_PREPEND_INDICATOR } from '../constants/dynamicInsightIndicator';

export function getClauses(documentData): string[] {
    const clauses: string[] = documentData.clauses ? Object.keys(documentData.clauses) : [];

    const allClausesArr = getDynamicInsightsClauses(documentData).concat(clauses);

    return allClausesArr.filter(
        (item, index, arr) =>
            index ===
            arr.findIndex(
                (foundItem) =>
                    foundItem.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, '') ===
                    item.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, '')
            )
    );
}

export function getDynamicInsightsClauses(documentData): string[] {
    const dynamicDataInsights: string[] = [];

    if (!documentData.dynamic_insights) return dynamicDataInsights;

    for (const key in documentData.dynamic_insights) {
        documentData.dynamic_insights[key].answers.map((answer: DynamicInsightsDataAnswer) => {
            dynamicDataInsights.push(
                `${DYNAMIC_INSIGHT_PREPEND_INDICATOR}${answer.question.alias}`
            );
        });
    }

    return dynamicDataInsights;
}
