import { useContext } from 'react';
import Container from '../../../UI/Common/Container';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../../pages/Client/GetInsights/context/getInsightsContext';
import { Draggable } from '@hello-pangea/dnd';
import SelectedClauseItem from '../SelectedClauseItem';
import ClausesListItem from '../ClausesListItem';

interface IDisplayedClausesProps {
    isConfirming: boolean;
    isResetting: boolean;
    removeInsight: (e, clause, index) => void;
    displayInsight: (clause, index) => void;
    editSelection: (e, clause) => void;
    reset: () => void;
    confirm: () => void;
}

const DisplayedClauses = ({
    isConfirming,
    isResetting,
    confirm,
    reset,
    removeInsight,
    displayInsight,
    editSelection,
}: IDisplayedClausesProps) => {
    const { selectedInsight, displayedClauses } = useContext(
        GetInsightsContext
    ) as GetInsightsContextType;
    return (
        <Container>
            {Boolean(displayedClauses.length) &&
                displayedClauses.map((clause, index) => (
                    <Container key={clause.id}>
                        {selectedInsight !== clause.key && (
                            <Draggable draggableId={clause.id} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <ClausesListItem
                                            index={index}
                                            clause={clause}
                                            removeInsight={removeInsight}
                                            displayInsight={displayInsight}
                                            isHideable={true}
                                            editSelection={editSelection}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        )}
                        {selectedInsight === clause.key && (
                            <SelectedClauseItem
                                reset={reset}
                                clause={clause}
                                isConfirming={isConfirming}
                                confirm={confirm}
                                isResetting={isResetting}
                                removeInsight={(e) => removeInsight(e, clause, index)}
                                editSelection={(e) => editSelection(e, clause)}
                            />
                        )}
                    </Container>
                ))}
        </Container>
    );
};

export default DisplayedClauses;
