import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Text } from '../../../UI/Typography';
import Container from '../../../UI/Common/Container';
import Flex from 'styled-flex-component';
import { CloseOutlined } from '@mui/icons-material';
import { useLazyEffect } from '../../../../hooks/useLazyEffect';
import { useState } from 'react';
import { getRecommendedType } from '../../../../api/dynamicInsights.api';

export default function QuestionField({ index, item, handleInput, questionTypes, formik }) {
    const [recommendedType, setRecommendedType] = useState<string>('');
    const [isLoadingType, setIsLoadingType] = useState<boolean>(false);
    async function loadRecommendedType() {
        setIsLoadingType(true);
        try {
            const response = await getRecommendedType(item.question, item.question_type);

            const responseType = response.recommendations[0].recommended_type;
            if (item.question_type === responseType) {
                setRecommendedType('');
            } else {
                setRecommendedType(responseType.charAt(0).toUpperCase() + responseType.slice(1));
            }
            setIsLoadingType(false);
        } catch (e) {
            setIsLoadingType(false);
        }
    }

    useLazyEffect(() => {
        if (item.question && item.question_type) {
            loadRecommendedType();
        }
    }, [item.question, item.question_type]);
    return (
        <Container key={index} bottomOuterSpacing={1}>
            <Container bottomOuterSpacing={0.5}>
                <Text customFontWeight={600}>Question {index + 1}</Text>
            </Container>
            <Flex justifyBetween style={{ gap: '8px' }} alignCenter>
                <Container bottomOuterSpacing={0.5} fullWidth>
                    <TextField
                        multiline
                        minRows="4"
                        sx={{ marginRight: '3px' }}
                        inputProps={{ style: { fontSize: 12 } }}
                        placeholder="Type the question"
                        label="Question"
                        name={`questions[${index}].question`}
                        required
                        value={item.question}
                        onChange={(e) => handleInput('question', e.target.value, index)}
                        fullWidth
                    />
                </Container>
                {formik.values.questions.length > 1 && (
                    <IconButton
                        onClick={() =>
                            formik.setFieldValue(
                                'questions',
                                formik.values.questions.filter((q, i) => i !== index)
                            )
                        }
                    >
                        <CloseOutlined />
                    </IconButton>
                )}
            </Flex>
            <Flex justifyBetween style={{ gap: '8px' }}>
                <FormControl fullWidth>
                    <InputLabel required id="question-type-select-label">
                        Question Type
                    </InputLabel>
                    <Select
                        required
                        placeholder="Question Type"
                        value={item.question_type}
                        label="Question Type"
                        name={`questions[${index}].question_type`}
                        onChange={(e) => handleInput('question_type', e.target.value, index)}
                    >
                        {questionTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type.charAt(0).toUpperCase() + type.slice(1)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Container topOuterSpacing={0.5}>
                        {isLoadingType && <Text>Loading type</Text>}
                        {!isLoadingType && recommendedType && (
                            <Text color="red">Recommended Type: {recommendedType}</Text>
                        )}
                    </Container>
                </FormControl>
                <TextField
                    sx={{ marginRight: '3px' }}
                    required
                    placeholder="Enter alias of the question"
                    label="Alias"
                    value={item.alias}
                    name={`questions[${index}].alias`}
                    onChange={(e) => handleInput('alias', e.target.value, index)}
                    fullWidth
                />
            </Flex>
        </Container>
    );
}
