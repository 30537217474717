import Flex from 'styled-flex-component';
import Container from '../../../components/UI/Common/Container';
import CardContainer from '../../../components/UI/Common/Card/Card';
import { Badge, Box, Tab, Tabs } from '@mui/material';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { MeliorTable } from '../../../components/MeliorTable';
import AuditLogsTable from '../../../components/AuditLogs/Table';
import { useEffect, useState } from 'react';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { toast } from 'react-toastify';
import Loading from '../../../components/UI/Common/Loading';
import { getAuditLogsByUser } from '../../../api/auditLogs';
import { IAuditLog } from '../../../@types/AuditLog';

const AuditLogsPage = () => {
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>();
    const [lastCursor, setLastCursor] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);
    const [auditLogs, setAuditLogs] = useState<IAuditLog[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(0);

    const showLoading = isLoading;

    useEffect(() => {
        getLogs();
    }, [counter, dateRange]);

    async function getLogs() {
        if (!lastCursor) {
            setIsLoading(true);
        } else {
            setIsFetchingNextPage(true);
        }

        try {
            const res = await getAuditLogsByUser(
                dateRange?.length ? dayjs(dateRange[0]).format('YYYY-MM-DD') : '',
                dateRange?.length ? dayjs(dateRange[1]).format('YYYY-MM-DD') : '',
                lastCursor
            );
            setLastCursor(res.next_timestamp);

            if (lastCursor) {
                setAuditLogs(auditLogs.concat(res.logs));
                setIsFetchingNextPage(false);
            } else {
                setAuditLogs(res.logs);
                setIsLoading(false);
            }
            setError(false);
        } catch (e) {
            setIsFetchingNextPage(false);
            setIsLoading(false);
            setError(true);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Flex justifyBetween>
                <span />
                <DateRangePicker
                    sx={{
                        '& .MuiInputBase-root': {
                            fontSize: '14px',
                            height: '37.125px',
                        },
                        '& .MuiFormLabel-root': {
                            fontSize: '14px',
                            top: '-5px',
                        },
                    }}
                    value={dateRange}
                    onChange={(e) => {
                        const firstTimestamp = e[0]?.toDate().getTime();
                        const secondTimestamp = e[1]?.toDate().getTime();
                        const THIRTY_DAYS_TIMESTAMP = 30 * 24 * 60 * 60 * 1000;

                        if (!(secondTimestamp && firstTimestamp)) return;

                        if (secondTimestamp - firstTimestamp > THIRTY_DAYS_TIMESTAMP) {
                            toast.error('Date range should not exceed to 30 days');
                            setDateRange([null, null]);
                            return;
                        }

                        setLastCursor(undefined);
                        setDateRange(e as DateRange<Dayjs>);
                    }}
                    disableFuture={true}
                    localeText={{ start: 'From', end: 'To' }}
                />
            </Flex>
            <Container topOuterSpacing={1.3}>
                <CardContainer>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Flex justifyBetween>
                            <Tabs value={0} style={{ overflow: 'visible' }}>
                                <Tab
                                    label={
                                        <Flex alignCenter>
                                            <span>
                                                <MeliorTranslate valueKey="Audit Logs" />
                                            </span>
                                            {!showLoading && (
                                                <Container leftOuterSpacing={1}>
                                                    <Badge
                                                        badgeContent={auditLogs.length}
                                                        color="primary"
                                                    />
                                                </Container>
                                            )}
                                        </Flex>
                                    }
                                />
                            </Tabs>
                        </Flex>
                    </Box>
                    <TabPanel value={0} index={0}>
                        <MeliorTable
                            showLoading={showLoading}
                            error={error}
                            items={auditLogs}
                            tableEl={
                                <AuditLogsTable
                                    auditLogs={auditLogs}
                                    fetchNextPage={() => setCounter((prev) => prev + 1)}
                                />
                            }
                            loadingMessage="Loading audit logs"
                            emptyMessage={t('No audit logs found')}
                            errorMessage="An error has occurred"
                        />
                        {isFetchingNextPage && (
                            <Flex justifyCenter>
                                <Loading message="" />
                            </Flex>
                        )}
                    </TabPanel>
                </CardContainer>
            </Container>
        </LocalizationProvider>
    );
};

export default AuditLogsPage;
