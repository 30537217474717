import Container from '../../../UI/Common/Container';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { IFeedback } from '../../../../@types/Feedback';
import { provideFeedback } from '../../../../api/feedback';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../../pages/Client/GetInsights/context/getInsightsContext';
import { useSearchParams } from 'react-router-dom';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { IDisplayClause } from '../../../../@types/Comparison';
import { getTemplates } from '../../../../api/template.api';
import { useClauseLibrary } from '../../../../hooks/useClauseLibrary';
import CreateListModal from '../../../Templates/CreateListModal';
import { getClauses } from '../../../../utils/getClauses';
import OtherInsights from './OtherInsights';
import UnlistedClauses from './UnlistedClauses';
import DisplayedClauses from './DisplayedClauses';
import ClausesLoadingPlaceholder from './ClausesLoadingPlaceholder';
import ClausesListHeader from './ClausesListHeader';
import { getBboxesValue } from '../../../../utils/getBoxesValue';

interface ICLausesListProps {
    getDocument: () => void;
    sidebarSize: number;
    newDocType: string;
}

const ClausesList = ({ getDocument, sidebarSize, newDocType }: ICLausesListProps) => {
    const [searchParams] = useSearchParams();
    const { clauseLibrary } = useClauseLibrary();
    const clauseSearchParam = searchParams.get('clause');
    const {
        documentData,
        editSelectedClause,
        selectedRegion,
        textMatchIndex,
        isRepublicHeaderWidgetOpen,
        draggedInsight,
        hiddenClauses,
        displayedClauses,
        currTemplate,
        templates,
        setTemplates,
        setCurrTemplate,
        setDraggedInsight,
        setHoveredInsight,
        setHiddenClauses,
        setIsFetchingClauses,
        setDisplayedClauses,
        setSelectedRegion,
        setEditSelectedClause,
        setSelectedInsight,
        setUnlistedClauses,
    } = useContext(GetInsightsContext) as GetInsightsContextType;
    const clauses = getClauses(documentData);
    const [initialClauses, setInitialClauses] = useState<Array<IDisplayClause>>([]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isResetting, setIsResetting] = useState<boolean>(false);
    const [isDisplayingBack, setIsDisplayingBack] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [docType, setDocType] = useState<string>('');

    /**use effect for checking if clause query param exists, select that clause as insight */
    useEffect(() => {
        setDocType(documentData.type);
        setIsFetchingClauses(true);
        getTemplates().then((res) => {
            setTemplates(res);
            getTemplateData(res);
        });
    }, []);

    useEffect(() => {
        setDocType(!isEmpty(newDocType) ? newDocType : documentData.type);
    }, [newDocType]);

    useEffect(() => {
        if (!clauseLibrary) return;

        getUnlistedClauses();
    }, [clauseLibrary]);

    useEffect(() => {
        if (!isEmpty(currTemplate) && currTemplate != 'None') getUnlistedClauses();
    }, [currTemplate]);

    const getUnlistedClauses = () => {
        if (!clauseLibrary) return setUnlistedClauses([]);

        const unlisted = clauseLibrary
            .filter((clause) => !clauses.includes(clause.name))
            .map((clause) => clause.name);
        setUnlistedClauses(unlisted);
    };

    const getTemplateData = (templates) => {
        const defaultTemplate = isEmpty(templates)
            ? []
            : templates.find((saved) => saved.linked_doc_types.includes(documentData.type));

        if (!isEmpty(defaultTemplate)) {
            // Get all visible clauses
            applyTemplate(defaultTemplate.visible);
            setCurrTemplate(defaultTemplate.template_name);
        } else {
            const clausesWithId = attachClauseId(clauses, 0);
            // Set all clauses as displayed clauses
            setDisplayedClauses(clausesWithId);
            setInitialClauses(clausesWithId.concat([]));
        }

        setIsFetchingClauses(false);
        if (!clauseSearchParam) return;
        if (!clauses.includes(clauseSearchParam)) return;

        setSelectedInsight(clauseSearchParam);
    };

    const applyTemplate = (visibles) => {
        // Merge clauses in saved template with document clauses
        const sessionClausesInDocument = visibles.filter((insight) => clauses.includes(insight));
        // Attach ID to each clause
        const clausesWithId = attachClauseId(sessionClausesInDocument, 0);
        // Set displayed clauses
        setDisplayedClauses(clausesWithId);
        setInitialClauses(clausesWithId.concat([]));

        // Set the rest of the clauses as hidden
        const documentClausesNotInSession = clauses.filter(
            (insight) => !visibles.includes(insight)
        );
        // Attach ID to each clause
        const hiddenClausesWithId = attachClauseId(
            documentClausesNotInSession,
            sessionClausesInDocument.length
        );
        // Set hidden clauses
        setHiddenClauses(hiddenClausesWithId);
        setIsFetchingClauses(false);
    };

    useEffect(() => {
        if (isExpanded || isDisplayingBack) {
            scrollToDisplayBottom();
        }
        if (isDisplayingBack) setIsDisplayingBack(false);
    }, [isExpanded, displayedClauses, isDisplayingBack]);

    useEffect(() => {
        setHasChanges(JSON.stringify(displayedClauses) != JSON.stringify(initialClauses));
    }, [draggedInsight, displayedClauses.length]);

    const attachClauseId = (clauses, startIndex) => {
        const clausesWithId: Array<IDisplayClause> = [];
        clauses.forEach((item, i) => {
            clausesWithId.push({
                id: (startIndex + i + 1).toString(),
                key: item,
            });
            return;
        });
        return clausesWithId;
    };

    async function reset() {
        setIsResetting(true);

        const feedback: IFeedback[] = [
            {
                correct: false,
                gt: documentData.clauses[editSelectedClause!.clause][textMatchIndex]._predicted
                    ?.answer as string,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause?.clause}`,
                key: 'answer',
            },
            {
                correct: false,
                gt: documentData.clauses[editSelectedClause!.clause][textMatchIndex]._predicted
                    ?.bboxes as number[][],
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause!.clause}`,
                key: 'bboxes',
            },
        ];
        try {
            await provideFeedback(documentData.id, feedback);
            setEditSelectedClause(undefined);
            setSelectedRegion(undefined);
            setIsResetting(false);
            getDocument();
        } catch (e) {
            console.error(e);
            setIsResetting(false);
        }
    }

    async function confirm() {
        if (!selectedRegion || !editSelectedClause) return;

        setIsConfirming(true);
        const feedback: IFeedback[] = [
            {
                correct: false,
                gt: selectedRegion.text,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'answer',
            },
            {
                correct: false,
                gt: getBboxesValue(selectedRegion),
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'bboxes',
            },
            {
                correct: false,
                gt: selectedRegion.page,
                idx: textMatchIndex,
                field: `clauses.${editSelectedClause.clause}`,
                key: 'page',
            },
        ];

        try {
            await provideFeedback(documentData.id, feedback);
            setEditSelectedClause(undefined);
            setIsConfirming(false);
            setSelectedRegion(undefined);
            getDocument();
        } catch (e) {
            console.error(e);
            setIsConfirming(false);
        }
    }

    const dragEnd = (result) => {
        setDraggedInsight('');
        const { destination, source, draggableId } = result;
        // Released outside of droppable area
        if (!destination) return;
        // Order not changed
        if (source.index == destination.index && source.droppableId == destination.droppableId)
            return;

        if (source.droppableId != destination.droppableId) {
            //remove from source
            const sourceList = source.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
            const clause = sourceList.find((clause) => clause.id == draggableId);
            sourceList.splice(source.index, 1);
            //insert to destination
            const destinationList =
                destination.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
            destinationList.splice(destination.index, 0, clause as IDisplayClause);
            // update lists
            const displayed = source.droppableId == 'displayed' ? sourceList : destinationList;
            const hidden = source.droppableId == 'hidden' ? sourceList : destinationList;
            setDisplayedClauses(displayed);
            setHiddenClauses(hidden);

            if (!hidden.length) setIsExpanded(false);

            return;
        }
        const listToUpdate = source.droppableId == 'displayed' ? displayedClauses : hiddenClauses;
        const clause = listToUpdate.find((clause) => clause.id == draggableId);
        listToUpdate.splice(source.index, 1);
        listToUpdate.splice(destination.index, 0, clause as IDisplayClause);
        source.droppableId == 'displayed'
            ? setDisplayedClauses(listToUpdate)
            : setHiddenClauses(listToUpdate);
    };

    const dragStart = (result) => {
        setDraggedInsight(result.draggableId);
    };

    const removeInsight = (e, clause, index) => {
        e.stopPropagation();

        if (hiddenClauses.includes(clause)) return;

        displayedClauses.splice(index, 1);
        setDisplayedClauses(displayedClauses);

        hiddenClauses.unshift(clause);
        setHiddenClauses(hiddenClauses);
        setHoveredInsight('');
    };

    const displayInsight = (clause, index) => {
        setIsDisplayingBack(true);
        if (displayedClauses.includes(clause)) return;

        hiddenClauses.splice(index, 1);
        setHiddenClauses(hiddenClauses);
        if (!hiddenClauses.length) setIsExpanded(false);

        displayedClauses.push(clause);
        setDisplayedClauses(displayedClauses);
        setHoveredInsight('');
    };

    const scrollToDisplayBottom = () => {
        const element: any = document.getElementById('displayed-clauses-bottom');
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const editSelection = (e, clause) => {
        e.stopPropagation();
        setEditSelectedClause({
            clause: clause.key,
            answer: documentData.clauses[clause.key][textMatchIndex].answer,
        });
        setSelectedRegion(undefined);
    };

    const onSaveTemplateSuccess = (template) => {
        setTemplates(templates.concat(template));
        setOpenCreate(false);
        setHasChanges(false);
        setCurrTemplate(template.template_name);
    };

    return (
        <>
            <ClausesListHeader
                hasChanges={hasChanges}
                docType={docType}
                openSelect={openSelect}
                applyTemplate={applyTemplate}
                setHasChanges={setHasChanges}
                setOpenSelect={setOpenSelect}
                setOpenCreate={setOpenCreate}
                setInitialClauses={setInitialClauses}
            />

            <DragDropContext onDragEnd={dragEnd} onDragStart={dragStart}>
                <Container id="clauses-list">
                    <ClausesLoadingPlaceholder clauses={clauses} />
                    <Droppable droppableId="displayed">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    height: isExpanded
                                        ? `calc(50vh - ${isRepublicHeaderWidgetOpen ? '276px' : '208px'})`
                                        : hiddenClauses.length
                                          ? `calc(100vh - ${isRepublicHeaderWidgetOpen ? '321px' : '253px'})`
                                          : `calc(100vh - ${isRepublicHeaderWidgetOpen ? '245px' : '177px'})`,
                                    overflowY: 'auto',
                                }}
                                id="displayed-clauses"
                            >
                                <DisplayedClauses
                                    isConfirming={isConfirming}
                                    isResetting={isResetting}
                                    reset={reset}
                                    confirm={confirm}
                                    removeInsight={removeInsight}
                                    editSelection={editSelection}
                                    displayInsight={displayInsight}
                                />
                                {provided.placeholder}
                                <Container id="displayed-clauses-bottom" />
                                <UnlistedClauses />
                            </div>
                        )}
                    </Droppable>
                    <OtherInsights
                        sidebarSize={sidebarSize}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        removeInsight={removeInsight}
                        editSelection={editSelection}
                        displayInsight={displayInsight}
                    />
                </Container>
            </DragDropContext>

            <CreateListModal
                isOpen={openCreate}
                setIsOpen={setOpenCreate}
                onSaveTemplateSuccess={(template) => onSaveTemplateSuccess(template)}
                maxStep={1}
                docType={docType}
                visibleClauses={displayedClauses.map((clause) => clause.key)}
                mode="new"
            />
        </>
    );
};

export default ClausesList;
