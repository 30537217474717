import { useContext } from 'react';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../pages/Client/GetInsights/context/getInsightsContext';
import { Theme } from '../../../theme';
import Container from '../../UI/Common/Container';
import Flex from 'styled-flex-component';
import { Text } from '../../UI/Typography';
import { Button, IconButton } from '@mui/material';
import { SelectAllOutlined, VisibilityOff } from '@mui/icons-material';
import { IDisplayClause } from '../../../@types/Comparison';
import { isEmpty } from 'lodash';
import TextMatchesNavigation from './TextMatchesNavigation';
import { MeliorTranslate } from '../../MeliorTranslate';
import { DYNAMIC_INSIGHT_PREPEND_INDICATOR } from '../../../constants/dynamicInsightIndicator';
import { DynamicInsightsDataAnswer } from '../../../@types/Document';
import EditedLabel from '../../UI/Common/EditedLabel';

interface SelectedClauseItemProps {
    clause: IDisplayClause;
    isResetting: boolean;
    isConfirming: boolean;
    confirm: () => void;
    reset: () => void;
    removeInsight: (e) => void;
    editSelection: (e) => void;
}

const SelectedClauseItem = ({
    clause,
    isResetting,
    isConfirming,
    confirm,
    removeInsight,
    reset,
    editSelection,
}: SelectedClauseItemProps) => {
    const {
        documentData,
        editSelectedClause,
        selectedRegion,
        textMatchIndex,
        setSelectedInsight,
        setTextMatchIndex,
    } = useContext(GetInsightsContext) as GetInsightsContextType;

    function extractDynamicInsight() {
        const dynamicDataInsightsAnswers: DynamicInsightsDataAnswer[] = [];

        for (const key in documentData.dynamic_insights) {
            documentData.dynamic_insights[key].answers.map((answer: DynamicInsightsDataAnswer) => {
                dynamicDataInsightsAnswers.push(answer);
            });
        }
        return dynamicDataInsightsAnswers.find(
            (answer) =>
                answer.question.alias === clause.key.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, '')
        );
    }

    const isEdited = !isEmpty(documentData.clauses[clause.key])
        ? documentData.clauses[clause.key][0].answer !==
          documentData.clauses[clause.key][0]._predicted?.answer
        : false;

    return (
        <Container
            borderLeft={`4px solid ${Theme.primary}`}
            borderBottom="1px solid rgba(13, 24, 50, 0.12)"
            leftInnerSpacing={2}
            topInnerSpacing={2}
            bottomInnerSpacing={1.5}
            rightInnerSpacing={1.625}
            onClick={() => {
                setTextMatchIndex(0);
                setSelectedInsight('');
            }}
            backgroundColor="white"
        >
            <Flex alignCenter justifyBetween>
                <Flex alignCenter justifyBetween>
                    <Text customFontWeight={600} color={Theme.primaryDark}>
                        {clause.key.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, '')}
                    </Text>
                    {isEdited && <EditedLabel />}
                </Flex>

                <IconButton
                    onClick={(e) => {
                        removeInsight(e);
                    }}
                >
                    <VisibilityOff
                        sx={{
                            color: 'rgba(13, 24, 50, 0.54)',
                        }}
                    />
                </IconButton>
            </Flex>

            {clause.key.includes(DYNAMIC_INSIGHT_PREPEND_INDICATOR) && (
                <>
                    <Container topOuterSpacing={1} whiteSpace="break-spaces">
                        <Text>
                            <b>Q:</b> {extractDynamicInsight()?.question.question}
                        </Text>
                    </Container>
                    <Container topOuterSpacing={1}>
                        <Text>
                            <b>A:</b> {extractDynamicInsight()?.answer}
                        </Text>
                    </Container>
                </>
            )}

            {!clause.key.includes(DYNAMIC_INSIGHT_PREPEND_INDICATOR) && (
                <>
                    <Container
                        topOuterSpacing={1.375}
                        backgroundColor={Theme.background}
                        leftInnerSpacing={0.75}
                        topInnerSpacing={1}
                        bottomInnerSpacing={1}
                        rightInnerSpacing={0.75}
                        minHeight={6.09375}
                        wordBreak
                    >
                        {isEmpty(documentData.clauses[clause.key]) && (
                            <i style={{ opacity: 0.4 }}>empty</i>
                        )}
                        {!isEmpty(documentData.clauses[clause.key]) && (
                            <Flex column justifyBetween style={{ minHeight: '6.09375rem' }}>
                                {documentData.clauses[clause.key].length && (
                                    <Text customFontSize={0.8125} customFontWeight={400}>
                                        {selectedRegion
                                            ? selectedRegion.text
                                            : documentData.clauses[clause.key][
                                                  documentData.clauses[clause.key].length > 1
                                                      ? textMatchIndex
                                                      : 0
                                              ].answer}
                                    </Text>
                                )}

                                {documentData.clauses[clause.key].length > 1 && (
                                    <Container topOuterSpacing={1}>
                                        <TextMatchesNavigation
                                            items={documentData.clauses[clause.key]}
                                        />
                                    </Container>
                                )}
                            </Flex>
                        )}
                    </Container>

                    {!Boolean(editSelectedClause) && (
                        <Container topOuterSpacing={1} textAlign="right">
                            <Flex full justifyEnd>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    onClick={(e) => editSelection(e)}
                                >
                                    <Flex alignCenter>
                                        <SelectAllOutlined />
                                        <Container leftOuterSpacing={0.6875}>
                                            <MeliorTranslate valueKey="Edit Selection" />
                                        </Container>
                                    </Flex>
                                </Button>
                            </Flex>
                        </Container>
                    )}
                    {Boolean(editSelectedClause) && (
                        <Container topOuterSpacing={1} textAlign="right">
                            <Flex full justifyEnd>
                                <Button
                                    color="primary"
                                    sx={{ marginRight: 1 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        reset();
                                    }}
                                    disabled={isResetting}
                                >
                                    <MeliorTranslate valueKey="Reset" />
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    disabled={isConfirming || !selectedRegion}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        confirm();
                                    }}
                                >
                                    <MeliorTranslate valueKey="Confirm" />
                                </Button>
                            </Flex>
                        </Container>
                    )}
                </>
            )}
        </Container>
    );
};

export default SelectedClauseItem;
