import Flex from 'styled-flex-component';
import { Text } from '../Typography';
import { Badge } from '@mui/material';
import { MeliorTranslate } from '../../MeliorTranslate';
import { IDocument } from '../../../@types/Document';
import { useState } from 'react';
import DocumentTags from '../DocumentTags';
import Container from '../../UI/Common/Container';

interface ITagDisplayProps {
    documentData: IDocument;
    selectedTags: string[];
    setIsAddTagOpen: (isOpen: boolean) => void;
}

const TagDisplay = ({ documentData, selectedTags, setIsAddTagOpen }: ITagDisplayProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    return (
        <Container
            leftInnerSpacing={0.4}
            rightInnerSpacing={0.4}
            style={{
                border: '1px solid',
                borderColor: isHovered ? 'black' : 'white',
                borderRadius: '20px',
                marginLeft: '15px',
                height: '40px',
                maxWidth: 'fit-content',
                background: '#FFFFFF14',
            }}
        >
            {selectedTags.length ? (
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Flex alignCenter style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                        <Badge badgeContent={selectedTags.length} color="primary" />
                    </Flex>
                    <DocumentTags
                        documentData={documentData}
                        selectedTags={selectedTags}
                        onClickTag={() => setIsAddTagOpen(true)}
                        onClickMore={() => setIsAddTagOpen(true)}
                    />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        maxWidth: '150px',
                        height: '100%',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => setIsAddTagOpen(true)}
                >
                    <Text color="white" customFontSize={0.83}>
                        <MeliorTranslate valueKey="Add Tags" />
                    </Text>
                </div>
            )}
        </Container>
    );
};

export default TagDisplay;
