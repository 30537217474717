import { Button, Grid, TextField, Divider } from '@mui/material';
import { Text } from '../../../UI/Typography';
import { MeliorTranslate } from '../../../MeliorTranslate';
import CardContainer from '../../../UI/Common/Card/Card';
import Flex from 'styled-flex-component';
import { useRef } from 'react';
import {
    saveQuestionCollection,
    updateQuestionCollection,
} from '../../../../api/dynamicInsights.api';
import { IDynamicQueryCollection } from '../../../../@types/DynamicInsightQueryCollection';
import { useQuestionTypes } from '../../../../hooks/useQuestionTypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Container from '../../../UI/Common/Container';
import { useFormik } from 'formik';
import { collectionValidationSchema } from './formSchema';
import QuestionField from './QuestionField';

interface SaveQuestionCollectionFormProps {
    collection: IDynamicQueryCollection | null | undefined;
    refetch: () => void;
    setSelectedCollection: (val: IDynamicQueryCollection | null) => void;
    setIsShowCollectionForm: (val: boolean) => void;
}

export default function SaveQuestionCollectionForm({
    collection,
    refetch,
    setSelectedCollection,
    setIsShowCollectionForm,
}: SaveQuestionCollectionFormProps) {
    const { t } = useTranslation();

    const { questionTypes } = useQuestionTypes();
    const questionsEndRef = useRef<null | HTMLDivElement>(null);

    const formik = useFormik({
        initialValues: {
            collectionName: collection?.name,
            questions: collection?.questions ?? [
                {
                    question: '',
                    question_id: '',
                    question_type: '',
                    alias: '',
                },
            ],
        },
        validationSchema: collectionValidationSchema,
        onSubmit: async (values) => {
            try {
                if (!collection?.id) {
                    //create collection
                    await saveQuestionCollection({
                        name: values.collectionName!,
                        questions: values.questions,
                    });
                } else {
                    //edit collection
                    await updateQuestionCollection({
                        name: values.collectionName!,
                        questions: values.questions,
                        id: collection.id,
                    });
                }
                toast.success('Collection saved successfully');
                refetch();
                setIsShowCollectionForm(false);
                setSelectedCollection(null);
            } catch (e) {
                formik.setSubmitting(false);
                toast.error('Something went wrong. Please try again');
                console.error(e);
            }
        },
    });

    const scrollToBottom = () => {
        questionsEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'start',
        });
    };

    function handleInput(state, value, index) {
        if (state === 'alias') {
            formik.setFieldValue(
                'questions',
                formik.values.questions?.map((list, i) =>
                    i === index ? { ...list, alias: value } : list
                )
            );
            return;
        }
        if (state === 'question') {
            formik.setFieldValue(
                'questions',
                formik.values.questions?.map((list, i) =>
                    i === index ? { ...list, question: value } : list
                )
            );
            return;
        }
        if (state === 'question_type') {
            formik.setFieldValue(
                'questions',
                formik.values.questions?.map((list, i) =>
                    i === index ? { ...list, question_type: value } : list
                )
            );
            return;
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <CardContainer>
                <Container topOuterSpacing={1}>
                    <Flex column>
                        <TextField
                            required
                            fullWidth
                            label={t('Collection Name')}
                            name="collectionName"
                            id="collectionName"
                            placeholder={t('Enter the name of the collection')}
                            value={formik.values.collectionName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.collectionName &&
                                Boolean(formik.errors.collectionName)
                            }
                            helperText={
                                formik.touched.collectionName && formik.errors.collectionName
                            }
                        />
                    </Flex>
                </Container>
                <Divider />
                <Container topOuterSpacing={1} maxHeight={18} overflow="auto">
                    <div style={{ height: '100%' }} ref={questionsEndRef}>
                        {formik.values.questions.map((item, index) => (
                            <QuestionField
                                key={index}
                                index={index}
                                item={item}
                                formik={formik}
                                handleInput={handleInput}
                                questionTypes={questionTypes.filter(
                                    (qtype) => qtype != 'categorical'
                                )}
                            />
                        ))}
                        <Button
                            onClick={() => {
                                formik.setFieldValue('questions', [
                                    ...formik.values.questions,
                                    { question: '', question_id: '', question_type: '', alias: '' },
                                ]);

                                setTimeout(() => {
                                    scrollToBottom();
                                }, 300);
                            }}
                        >
                            <MeliorTranslate valueKey="Add Question" />
                        </Button>
                    </div>
                </Container>
            </CardContainer>
            <Grid marginTop={2} container gap={2} sx={{ marginBottom: 2 }}>
                <Button variant="contained" disabled={formik.isSubmitting} type="submit">
                    <Text uppercase customletterSpacing={0.05}>
                        <MeliorTranslate valueKey="Save Collection" />
                    </Text>
                </Button>
                <Button
                    onClick={() => {
                        setIsShowCollectionForm(false);
                        setSelectedCollection(null);
                    }}
                >
                    <Text customFontFamily="Poppins" customFontWeight={500} uppercase>
                        <MeliorTranslate valueKey="Cancel" />
                    </Text>
                </Button>
            </Grid>
        </form>
    );
}
