import { useState } from 'react';
import { IDocument } from '../../../@types/Document';
import { useDynamicInsightsCollection } from '../../../hooks/useDynamicInsightsCollection';
import { MeliorTable } from '../../MeliorTable';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from '@mui/material';
import LaunchDynamicInsightsTable from './Table';
import Flex from 'styled-flex-component';
import { H5, Text } from '../../UI/Typography';
import { MeliorTranslate } from '../../MeliorTranslate';
import { CloseOutlined } from '@mui/icons-material';
import Container from '../../UI/Common/Container';
import { DocumentStatus } from '../../../enums/DocumentStatus';

interface ILaunchDynamicInsightsModalProps {
    documents: IDocument[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isReadyByDefault: boolean;
}

export default function LaunchDynamicInsightsModal({
    isOpen,
    documents,
    setIsOpen,
    isReadyByDefault,
}: ILaunchDynamicInsightsModalProps) {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const params = {
        page: page,
        size: 5,
    };

    const { isLoading, error, totalPages, collections, refetch } =
        useDynamicInsightsCollection(params);
    const showLoading = isLoading;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '45%',
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: '10px',
    };

    return (
        <Modal open={isOpen} aria-labelledby="add-tag-title" aria-describedby="add-tag-description">
            <Box sx={style}>
                <Flex style={{ marginBottom: '20px' }} column>
                    <Flex justifyBetween alignTop style={{ marginBottom: '10px' }}>
                        <H5>
                            <MeliorTranslate valueKey={'Dynamic Insights'} />
                        </H5>
                        <Container onClick={() => setIsOpen(false)}>
                            <CloseOutlined
                                sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                            />
                        </Container>
                    </Flex>

                    <MeliorTranslate valueKey="Click Run Insight to run the collection against your selected documents." />
                    {!isReadyByDefault && (
                        <Text
                            customFontSize={0.8125}
                            customletterSpacing={0}
                            color="gray"
                            style={{ marginTop: '5px' }}
                        >
                            <MeliorTranslate valueKey="Note: Only selected documents with a 'Ready' status will be included in the process." />
                        </Text>
                    )}
                </Flex>
                <Container overflow="auto" maxHeight="400px">
                    <MeliorTable
                        hideTopPageNavigator={true}
                        showLoading={showLoading}
                        error={error}
                        items={collections}
                        page={page}
                        totalPages={totalPages}
                        setPage={setPage}
                        tableEl={
                            <LaunchDynamicInsightsTable
                                collections={collections}
                                refetch={refetch}
                                documents={
                                    isReadyByDefault
                                        ? documents
                                        : documents.filter(
                                              (document) => document.status == DocumentStatus.READY
                                          )
                                }
                            />
                        }
                        loadingMessage="Loading question collections"
                        emptyMessage={t('No question collections found')}
                        errorMessage="An error has occurred"
                    />
                </Container>
            </Box>
        </Modal>
    );
}
