import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import WorkflowsTableItem from './Item';
import { MeliorTranslate } from '../../MeliorTranslate';
import { Text } from '../../UI/Typography';
import { IAuditLog } from '../../../@types/AuditLog';
import { useEffect, useRef } from 'react';

interface IAuditLogsTableProps {
    auditLogs: IAuditLog[];
    fetchNextPage: () => void;
}

const AuditLogsTable = ({ auditLogs, fetchNextPage }: IAuditLogsTableProps) => {
    const observerTarget = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    fetchNextPage();
                }
            },
            { threshold: 1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget]);
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '20%', minWidth: '130px' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Date" />
                            </Text>
                        </TableCell>
                        <TableCell style={{ width: '10%' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="User" />
                            </Text>
                        </TableCell>
                        <TableCell style={{ width: '70%' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Event" />
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {auditLogs.map((item, index) => (
                        <WorkflowsTableItem auditLog={item} key={index} />
                    ))}
                </TableBody>
            </Table>
            <div ref={observerTarget}></div>
        </TableContainer>
    );
};

export default AuditLogsTable;
