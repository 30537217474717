import { ISelectedRegion } from '../components/GetInsights/helpers/types';

export const getBboxesValue = (selectedRegion: ISelectedRegion): number[][] => {
    const arr: number[][] = [];
    if (!selectedRegion?.bboxes.length) {
        return [];
    }

    selectedRegion.bboxes.map((bbox) => {
        arr.push([bbox.left, bbox.top, bbox.width, bbox.height]);
    });

    return arr as number[][];
};
