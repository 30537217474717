import Flex from 'styled-flex-component';
import Container from '../Common/Container';
import { Text } from '../Typography';
import { MeliorTranslate } from '../../MeliorTranslate';
import { TextField } from '@material-ui/core';

interface DocumentNameFilterSelectProps {
    searchText: string;
    onChange: (key) => void;
}

const DocumentNameFilter = ({ searchText, onChange }: DocumentNameFilterSelectProps) => {
    return (
        <Flex alignCenter>
            <Container width={5} rightOuterSpacing={0.875}>
                <Text color="rgba(0, 0, 0, 0.6)">
                    <MeliorTranslate valueKey="Document Name" />:
                </Text>
            </Container>
            <TextField
                value={searchText}
                variant="outlined"
                size="small"
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            />
        </Flex>
    );
};

export default DocumentNameFilter;
