import { Box, Button, Modal } from '@mui/material';
import Flex from 'styled-flex-component';
import Container from '../../../UI/Common/Container';
import { H5, Text } from '../../../UI/Typography';
import { MeliorTranslate } from '../../../MeliorTranslate';
import { CloseOutlined } from '@mui/icons-material';
import DocumentTableSelector from '../../../DocumentTableSelector';
import { useState } from 'react';
import { IDocument } from '../../../../@types/Document';
import { toast } from 'react-toastify';
import { runDynamicQADoc } from '../../../../api/dynamicInsights.api';
import { IDynamicQueryCollection } from '../../../../@types/DynamicInsightQueryCollection';
import { isEmpty } from 'lodash';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 768,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
    height: 500,
};

interface Props {
    isOpen: boolean;
    collection: IDynamicQueryCollection;
    setIsOpen: (val: boolean) => void;
}

export default function RunCollectionModal({ isOpen, collection, setIsOpen }: Props) {
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    async function selectDocument() {
        if (!selectedDocuments || !collection) return;

        setIsRunning(true);
        try {
            await runDynamicQADoc(
                selectedDocuments.map((doc) => doc.id),
                collection.id!
            );
            closeModal();
            toast.success('Dynamic insights has launched successfully.');
            setIsRunning(false);
        } catch (e) {
            toast.error('Something went wrong.');
            setIsRunning(false);
        }
    }

    function closeModal() {
        setIsOpen(false);
        setSelectedDocuments([]);
    }

    return (
        <Modal
            open={isOpen}
            aria-labelledby="save-question-collection-modal-title"
            aria-describedby="save-question-collection-modal-description"
        >
            <Box sx={style}>
                <Flex column justifyBetween full>
                    <Container>
                        <Flex justifyBetween alignTop>
                            <Container bottomOuterSpacing={0.75}>
                                <H5>
                                    <MeliorTranslate valueKey="Select a document" />
                                </H5>
                            </Container>
                            <Container onClick={closeModal}>
                                <CloseOutlined
                                    sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                                />
                            </Container>
                        </Flex>
                        <Container topOuterSpacing={1} height={'400px'} overflow="auto">
                            <DocumentTableSelector
                                selectedDocuments={selectedDocuments!}
                                setSelectedDocuments={setSelectedDocuments}
                            />
                        </Container>
                    </Container>
                    <Container topInnerSpacing={1}>
                        <Flex justifyEnd>
                            <Container>
                                <Button onClick={closeModal}>
                                    <Text
                                        customFontFamily="Poppins"
                                        customFontWeight={500}
                                        uppercase
                                    >
                                        <MeliorTranslate valueKey="Cancel" />
                                    </Text>
                                </Button>
                            </Container>
                            <Container leftOuterSpacing={0.5}>
                                <Button
                                    onClick={() => selectDocument()}
                                    disabled={isRunning || !selectedDocuments.length}
                                    variant="contained"
                                >
                                    <Text
                                        customFontFamily="Poppins"
                                        customFontWeight={500}
                                        uppercase
                                        style={{ display: 'flex', gap: '5px' }}
                                    >
                                        <MeliorTranslate valueKey="Select" />
                                        {!isEmpty(selectedDocuments) && (
                                            <span>({selectedDocuments.length})</span>
                                        )}
                                    </Text>
                                </Button>
                            </Container>
                        </Flex>
                    </Container>
                </Flex>
            </Box>
        </Modal>
    );
}
