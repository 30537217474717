import { Close, SearchOutlined } from '@mui/icons-material';
import Container from '../../Common/Container';
import { InputAdornment } from '@mui/material';
import { StyledSearchTextField } from '../../../AdvancedSearch/SearchSectionInput/StyledTextField';
import { useState } from 'react';

interface ISearchBoxProps {
    placeholder?: string;
    onChange: (string) => void;
    onBlur?: () => void;
    onKeyDown?: (e: KeyboardEvent) => void;
}

const SearchBox = ({ placeholder = 'Search', onBlur, onKeyDown, onChange }: ISearchBoxProps) => {
    const [searchText, setSearchText] = useState<string>();
    return (
        <Container width="616px">
            <StyledSearchTextField
                fullWidth
                size="small"
                autoComplete="off"
                sx={{ background: 'rgba(0, 0, 0, 0.06)' }}
                placeholder={placeholder}
                value={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value);
                    onChange(e.target.value);
                }}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchText && (
                                <Container
                                    topOuterSpacing={0.4}
                                    cursor="pointer"
                                    onClick={() => {
                                        setSearchText('');
                                        onChange('');
                                    }}
                                >
                                    <Close fontSize="small" />
                                </Container>
                            )}
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                }}
            />
        </Container>
    );
};

export default SearchBox;
