import Flex from 'styled-flex-component';
import { Text } from '../../UI/Typography';
import Container from '../../UI/Common/Container';
import { Theme } from '../../../theme';
import { DragIndicator, Visibility, VisibilityOff, Warning } from '@mui/icons-material';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { DYNAMIC_INSIGHT_PREPEND_INDICATOR } from '../../../constants/dynamicInsightIndicator';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../pages/Client/GetInsights/context/getInsightsContext';

interface ICLausesListItemProps {
    index: number;
    clause: {
        id: string;
        key: string;
    };
    isHideable: boolean;
    removeInsight: (e: any, clause: any, index: any) => void;
    displayInsight: (clause: any, index: any) => void;
    editSelection: (e: any, clause: any) => void;
}

const ClausesListItem = ({
    index,
    clause,
    isHideable,
    editSelection,
    removeInsight,
    displayInsight,
}: ICLausesListItemProps) => {
    const {
        hoveredInsight,
        draggedInsight,
        isClauseParsed,
        setSelectedInsight,
        setHoveredInsight,
        isEditing,
        setTextMatchIndex,
    } = useContext(GetInsightsContext) as GetInsightsContextType;

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const clauseText = clause.key;

    return (
        <Container
            onClick={() => {
                setTextMatchIndex(0);
                setSelectedInsight(isHideable ? clauseText : '');
            }}
            pointerEvents={isEditing(clauseText) && isHideable ? 'none' : ''}
            backgroundColor={
                isEditing(clauseText) && isHideable
                    ? 'rgba(13, 24, 50, 0.3)'
                    : hoveredInsight == clauseText || draggedInsight == clause.id
                      ? 'whitesmoke'
                      : 'white'
            }
            display="flex"
            data-testid="clause-row"
            cursor="pointer"
            borderBottom="1px solid rgba(13, 24, 50, 0.12)"
            rightInnerSpacing={1}
            height={5}
            onMouseEnter={() => {
                setHoveredInsight(clauseText);
            }}
            onMouseLeave={() => {
                setHoveredInsight('');
            }}
        >
            <Flex alignCenter justifyCenter>
                <IconButton
                    color="default"
                    style={{
                        visibility:
                            hoveredInsight == clauseText || draggedInsight == clause.id
                                ? 'visible'
                                : 'hidden',
                        height: '40px',
                        backgroundColor: 'transparent',
                    }}
                >
                    <DragIndicator />
                </IconButton>
            </Flex>
            <Flex justifyBetween alignCenter style={{ width: '100%' }}>
                <Flex alignCenter>
                    {!isClauseParsed(clause.key) && (
                        <Tooltip
                            PopperProps={{ style: { pointerEvents: 'none' } }}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            title="Unable to extract the corresponding insight."
                            style={{ background: 'yellow' }}
                        >
                            <IconButton
                                style={{
                                    backgroundColor: 'transparent',
                                    padding: 0,
                                    borderRadius: 0,
                                    marginRight: '5px',
                                }}
                                onClick={(e) => {
                                    setSelectedInsight(isHideable ? clauseText : '');
                                    editSelection(e, clause);
                                }}
                            >
                                <Warning
                                    sx={{ color: Theme.warning }}
                                    style={{ marginRight: '5px', cursor: 'default' }}
                                />
                                {open && (
                                    <Chip
                                        label="Edit"
                                        style={{ height: '25px', cursor: 'pointer' }}
                                        color="warning"
                                        variant="outlined"
                                    />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                    <Text
                        color={
                            isHideable
                                ? Theme.primaryDark
                                : hoveredInsight == clauseText || draggedInsight == clause.id
                                  ? Theme.primaryDark
                                  : 'darkgray'
                        }
                        dangerouslySetInnerHTML={{
                            __html: clause.key.replace(DYNAMIC_INSIGHT_PREPEND_INDICATOR, ''),
                        }}
                    />
                </Flex>
            </Flex>
            <Flex alignCenter justifyCenter>
                <IconButton
                    color="default"
                    style={{
                        visibility: hoveredInsight == clauseText ? 'visible' : 'hidden',
                        height: '40px',
                    }}
                    onClick={(e) => {
                        isHideable
                            ? removeInsight(e, clause, index)
                            : displayInsight(clause, index);
                    }}
                >
                    {isHideable ? (
                        <VisibilityOff sx={{ color: 'rgba(13, 24, 50, 0.54)' }} />
                    ) : (
                        <Visibility sx={{ color: 'rgba(13, 24, 50, 0.54)' }} />
                    )}
                </IconButton>
            </Flex>
        </Container>
    );
};

export default ClausesListItem;
