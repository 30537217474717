import { useContext } from 'react';
import Container from '../../../UI/Common/Container';
import {
    GetInsightsContext,
    GetInsightsContextType,
} from '../../../../pages/Client/GetInsights/context/getInsightsContext';
import Flex from 'styled-flex-component';
import { Text } from '../../../UI/Typography';

const UnlistedClauses = () => {
    const { unlistedClauses, currTemplate } = useContext(
        GetInsightsContext
    ) as GetInsightsContextType;

    if (!(Boolean(unlistedClauses.length) && currTemplate && currTemplate != 'None')) return <></>;

    return (
        <Container>
            {unlistedClauses.map((clause, index) => (
                <div key={index} id={clause}>
                    <Flex
                        style={{
                            height: '80px',
                            paddingLeft: '35px',
                            borderBottom: '1px solid rgba(13, 24, 50, 0.12)',
                            backgroundColor: '#eeeeee',
                        }}
                        alignCenter
                    >
                        <Text color={'#666666'} dangerouslySetInnerHTML={{ __html: clause }} />
                    </Flex>
                </div>
            ))}
        </Container>
    );
};

export default UnlistedClauses;
