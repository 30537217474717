import { Chip } from '@mui/material';
import NavbarStyled from './Navbar.styled';
import Flex from 'styled-flex-component';
import ProfileAvatar from './Avatar/Avatar';
import Container from '../Common/Container';
import CopyAccessTokenButton from '../CopyAccessTokenButton/CopyAccessTokenButton';
import getSubscriptionStatus from '../../../utils/getSubscriptionStatus';
import { SubscriptionStatus } from '../../../enums/SubscriptionStatus';
import LanguageMenu from './LanguageMenu';
import NavbarPageTitle from './PageTitle';
import GlobalNotificationsButton from '../../GlobalNotificationsButton/GlobalNotificationsButton';

interface NavbarProps {
    isSideBarMaximized: boolean;
}

const Navbar = ({ isSideBarMaximized }: NavbarProps) => {
    const subscriptionStatus = getSubscriptionStatus();

    const getSubscriptionColor = () => {
        switch (subscriptionStatus) {
            case SubscriptionStatus.TRIAL:
                return 'warning';
            case SubscriptionStatus.PAYMENT_OVERDUE:
                return 'error';
            default:
                return 'success';
        }
    };

    return (
        <NavbarStyled isSideBarMaximized={isSideBarMaximized}>
            <Container leftInnerSpacing={1.5} rightInnerSpacing={1.5}>
                <Flex justifyBetween alignCenter>
                    <NavbarPageTitle />

                    <Flex justifyEvenly alignCenter>
                        <Container leftOuterSpacing={0.5}>
                            <Flex justifyEvenly alignCenter>
                                <GlobalNotificationsButton />
                                <CopyAccessTokenButton />
                                <LanguageMenu />
                            </Flex>
                        </Container>
                        <Container leftInnerSpacing={0.5}>
                            <Chip
                                label={subscriptionStatus}
                                color={getSubscriptionColor()}
                                variant="outlined"
                                style={{ width: '150px' }}
                            />
                        </Container>
                        <Container leftOuterSpacing={0.875}>
                            <ProfileAvatar />
                        </Container>
                    </Flex>
                </Flex>
            </Container>
        </NavbarStyled>
    );
};

export default Navbar;
