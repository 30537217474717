import React, { createContext, useState } from 'react';
import { IDocument } from '../../../../@types/Document';
import { InsightType } from '../../../../enums/InsightType';
import {
    IEditSelectedClause,
    ISelectedEntity,
    ISelectedRegion,
} from '../../../../components/GetInsights/helpers/types';
import PageLoadingPlaceholder from '../../../../components/PageLoadingPlaceholder/PageLoadingPlaceholder';
import { IDisplayClause } from '../../../../@types/Comparison';
import { ITemplate } from '../../../../@types/Template';
import { isEmpty } from 'lodash';

export interface IChatSource {
    page: number;
    chunk_box: Array<Array<number>>;
}

export interface IChat {
    isFromMe: boolean;
    message: string;
    sources?: IChatSource[];
}

export type GetInsightsContextType = {
    documentData: IDocument;
    isLoadingDocumentData: boolean;
    isDocumentLoaded: boolean;
    selectedInsight: string | ISelectedEntity | undefined;
    selectedInsightType: InsightType;
    editSelectedClause: IEditSelectedClause | undefined;
    selectedRegion: ISelectedRegion | undefined;
    selectedSourcePageIndex: number;
    textMatchIndex: number;
    chats: IChat[];
    currentPage: number;
    numPages: number;
    fileUrl: string;
    currentMatchIndex: number;
    matchCase: boolean;
    matchWord: boolean;
    selectedChunkBox: Array<Array<number>>;
    isRepublicHeaderWidgetOpen: boolean;
    hoveredInsight: string;
    draggedInsight: string;
    hiddenClauses: IDisplayClause[];
    displayedClauses: IDisplayClause[];
    unlistedClauses: string[];
    currTemplate: string;
    isFetchingClauses: boolean;
    templates: ITemplate[];
    setSelectedChunkBox: (chunkBox: Array<Array<number>>) => void;
    setSelectedInsight: (insight: string | ISelectedEntity | undefined) => void;
    setSelectedInsightType: (type: InsightType) => void;
    setEditSelectedClause: (clause: IEditSelectedClause | undefined) => void;
    setSelectedRegion: (region: ISelectedRegion | undefined) => void;
    setSelectedSourcePageIndex: (pageIndex: number) => void;
    setTextMatchIndex: (index: number) => void;
    setChats: (chats: IChat[]) => void;
    setCurrentPage: (page: number) => void;
    setNumPages: (pages: number) => void;
    setIsDocumentLoaded: (val: boolean) => void;
    setCurrentMatchIndex: (index: number) => void;
    setMatchCase: (matchCase: boolean) => void;
    setMatchWord: (matchWord: boolean) => void;
    setHoveredInsight: (insight: string) => void;
    setDraggedInsight: (insight: string) => void;
    isEditing: (key: string) => boolean;
    setHiddenClauses: (clauses: IDisplayClause[]) => void;
    setUnlistedClauses: (clauses: string[]) => void;
    setDisplayedClauses: (clauses: IDisplayClause[]) => void;
    setCurrTemplate: (val: string) => void;
    setIsFetchingClauses: (val: boolean) => void;
    setTemplates: (templates: ITemplate[]) => void;
    isClauseParsed: (key: string) => boolean;
};

export const GetInsightsContext = createContext<GetInsightsContextType | null>(null);

interface GetInsightsProviderProps {
    children: React.ReactNode;
    documentData?: IDocument;
    fileUrl: string;
    isRepublicHeaderWidgetOpen: boolean;
    isLoadingDocumentData: boolean;
}

const GetInsightsProvider = ({
    children,
    documentData,
    fileUrl,
    isLoadingDocumentData,
    isRepublicHeaderWidgetOpen,
}: GetInsightsProviderProps) => {
    const [selectedInsight, setSelectedInsight] = useState<string | ISelectedEntity | undefined>();
    const [hoveredInsight, setHoveredInsight] = useState<string>('');
    const [draggedInsight, setDraggedInsight] = useState<string>('');
    const [hiddenClauses, setHiddenClauses] = useState<Array<IDisplayClause>>([]);
    const [displayedClauses, setDisplayedClauses] = useState<Array<IDisplayClause>>([]);
    const [unlistedClauses, setUnlistedClauses] = useState<Array<string>>([]);
    const [currTemplate, setCurrTemplate] = useState<string>('');
    const [isFetchingClauses, setIsFetchingClauses] = useState<boolean>(false);
    const [templates, setTemplates] = useState<Array<ITemplate>>([]);
    const [selectedInsightType, setSelectedInsightType] = useState<InsightType>(InsightType.CLAUSE);
    const [selectedChunkBox, setSelectedChunkBox] = useState<Array<Array<number>>>([]);
    const [editSelectedClause, setEditSelectedClause] = useState<IEditSelectedClause | undefined>();
    const [selectedRegion, setSelectedRegion] = useState<ISelectedRegion | undefined>(undefined);
    const [selectedSourcePageIndex, setSelectedSourcePageIndex] = useState<number>(0);
    const [chats, setChats] = useState<IChat[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [numPages, setNumPages] = useState<number>(0);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
    const [textMatchIndex, setTextMatchIndex] = useState<number>(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(0);
    const [matchCase, setMatchCase] = useState(false);
    const [matchWord, setMatchWord] = useState(false);

    const isEditing = (key) => Boolean(editSelectedClause && editSelectedClause.clause !== key);

    const isClauseParsed = (key: string) => {
        if (!documentData?.clauses[key]) return true; //Dynamic Insights aren't included in this check; true by default

        return (
            (key.includes('Date') && !isEmpty(documentData.clauses[key][0]?.value)) ||
            !key.includes('Date')
        );
    };

    if (isLoadingDocumentData || !documentData) {
        return <PageLoadingPlaceholder />;
    }

    return (
        <GetInsightsContext.Provider
            value={{
                documentData,
                fileUrl,
                isDocumentLoaded,
                selectedInsight,
                selectedInsightType,
                editSelectedClause,
                selectedRegion,
                selectedSourcePageIndex,
                textMatchIndex,
                chats,
                currentPage,
                numPages,
                isLoadingDocumentData,
                currentMatchIndex,
                matchCase,
                matchWord,
                selectedChunkBox,
                isRepublicHeaderWidgetOpen,
                hoveredInsight,
                templates,
                draggedInsight,
                isFetchingClauses,
                hiddenClauses,
                displayedClauses,
                unlistedClauses,
                currTemplate,
                isClauseParsed,
                setCurrTemplate,
                setUnlistedClauses,
                setTemplates,
                setHiddenClauses,
                setDisplayedClauses,
                setDraggedInsight,
                setHoveredInsight,
                setIsFetchingClauses,
                setSelectedChunkBox,
                setSelectedInsight,
                setIsDocumentLoaded,
                setSelectedInsightType,
                setEditSelectedClause,
                setSelectedRegion,
                setSelectedSourcePageIndex,
                setTextMatchIndex,
                setChats,
                setCurrentPage,
                setNumPages,
                setCurrentMatchIndex,
                setMatchCase,
                setMatchWord,
                isEditing,
            }}
        >
            {children}
        </GetInsightsContext.Provider>
    );
};

export default GetInsightsProvider;
