import { Button, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import Container from '../../UI/Common/Container';
import { Text } from '../../UI/Typography';
import { MeliorTranslate } from '../../MeliorTranslate';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import Flex from 'styled-flex-component';
import RunCollectionModal from '../Modals/RunCollection';
import { useState } from 'react';
import DeleteQuestionCollectionModal from '../Modals/DeleteQuestionCollection';
import { IDynamicQueryCollection } from '../../../@types/DynamicInsightQueryCollection';
import { ICollectionQuestion } from '../../../@types/CollectionQuestion';
import AliasInfoModal from '../Modals/AliasInfo';
import { useTranslation } from 'react-i18next';

interface IDynamicInsightsTableItemProps {
    collectionItem: IDynamicQueryCollection;
    setSelectedCollection: (val: IDynamicQueryCollection) => void;
    refetch: () => void;
}

export default function DynamicInsightsTableItem({
    collectionItem,
    setSelectedCollection,
    refetch,
}: IDynamicInsightsTableItemProps) {
    const { t } = useTranslation();
    const [isOpenRun, setIsOpenRun] = useState<boolean>(false);
    const [isOpenAlias, setIsOpenAlias] = useState<boolean>(false);
    const [selectedAlias, setSelectedAlias] = useState<ICollectionQuestion>();
    const [isDeleteQuestionOpen, setIsDeleteQuestionOpen] = useState<boolean>(false);

    function runCollection() {
        setIsOpenRun(true);
    }

    function openAlias(alias) {
        setSelectedAlias(alias);
        setIsOpenAlias(true);
    }

    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: '25%' }}>{collectionItem.name}</TableCell>
                <TableCell style={{ width: '25%' }}>
                    {collectionItem.questions.map((question) => (
                        <Container
                            key={question.alias}
                            cursor="pointer"
                            onClick={() => openAlias(question)}
                        >
                            <Text color="rgb(3, 138, 255)">{question.alias}</Text>{' '}
                        </Container>
                    ))}
                </TableCell>
                <TableCell style={{ width: '25%' }}>
                    <Flex alignCenter justifyBetween>
                        <Button variant="outlined" onClick={() => runCollection()}>
                            <Text>
                                <MeliorTranslate valueKey="Run Insights" />
                            </Text>
                        </Button>
                        <Tooltip title={t('Edit')}>
                            <IconButton
                                color="inherit"
                                onClick={() => setSelectedCollection(collectionItem)}
                            >
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Delete')}>
                            <IconButton
                                color="inherit"
                                onClick={() => setIsDeleteQuestionOpen(true)}
                            >
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    </Flex>
                </TableCell>
            </TableRow>
            <AliasInfoModal
                question={selectedAlias}
                isOpen={isOpenAlias}
                setIsOpen={setIsOpenAlias}
            />
            <RunCollectionModal
                collection={collectionItem}
                isOpen={isOpenRun}
                setIsOpen={setIsOpenRun}
            />
            <DeleteQuestionCollectionModal
                isOpen={isDeleteQuestionOpen}
                collection={collectionItem}
                setIsOpen={setIsDeleteQuestionOpen}
                handleDelete={() => {
                    setIsDeleteQuestionOpen(false);
                    refetch();
                }}
            />
        </>
    );
}
