import { Button, ButtonProps } from '@mui/material';

import { ReactNode } from 'react';
import DocViewThemeWrapper from '../ThemeWrapper/DocViewThemeWrapper';

interface Props extends ButtonProps {
    children?: ReactNode;
}

export function DocViewHeaderButton({ children, ...buttonProps }: Props) {
    return (
        <DocViewThemeWrapper>
            <Button
                color="primary"
                variant="outlined"
                style={{
                    borderRadius: '100px',
                    height: '40px',
                    fontSize: '0.83rem',
                    maxWidth: 'fit-content',
                    textTransform: 'none',
                }}
                {...buttonProps}
            >
                {children}
            </Button>
        </DocViewThemeWrapper>
    );
}
