import { MoreVert } from '@mui/icons-material';
import { ReactElement, useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { IDocument } from '../../../../@types/Document';
import { DocumentStatus } from '../../../../enums/DocumentStatus';
import { useTranslation } from 'react-i18next';

interface Props {
    document: IDocument;
    isTagsEditable: boolean;
    isDeletable: boolean;
    setDocumentsForDynInsights: (doc: IDocument[]) => void;
    addTag: () => void;
    setIsDynamicInsightsOpen: (isOpen: boolean) => void;
    setShowDeleteDialog: (isOpen: boolean) => void;
    downloadDocument: () => void;
    actionItems?: {
        component: ReactElement;
    }[];
}

export default function DocumentActions({
    document,
    isTagsEditable,
    isDeletable,
    setDocumentsForDynInsights,
    addTag,
    setIsDynamicInsightsOpen,
    setShowDeleteDialog,
    downloadDocument,
    actionItems,
}: Props) {
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setDocumentsForDynInsights([document]);
    };
    const { t } = useTranslation();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        {
            name: isTagsEditable ? 'Edit Tags' : 'Add Tags',
            function: () => {
                addTag();
            },
            isEnabled: document.status == DocumentStatus.READY,
        },
        {
            name: 'Launch Dynamic Insights',
            function: () => {
                setIsDynamicInsightsOpen(true);
            },
            isEnabled: document.status == DocumentStatus.READY,
        },
        {
            name: 'Download',
            function: () => downloadDocument(),
            isEnabled: document.status != DocumentStatus.ERROR,
        },
        {
            name: 'Delete',
            function: () => {
                setShowDeleteDialog(true);
            },
            isEnabled:
                document.status == DocumentStatus.READY || document.status == DocumentStatus.ERROR,
        },
    ];

    useEffect(() => {
        if (!isDeletable) options.filter((option) => option.name == 'Delete');
    }, [isDeletable]);
    return (
        <div>
            <Tooltip title={t('All Actions')}>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                >
                    <MoreVert />
                </IconButton>
            </Tooltip>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                        },
                    },
                }}
            >
                {actionItems?.map((item) => item.component)}

                {options.map((option) => (
                    <MenuItem
                        key={option.name}
                        onClick={option.function}
                        disabled={!option.isEnabled}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
