import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    MenuItem,
} from '@mui/material';
import { useRef, useState } from 'react';
import { DocViewHeaderButton } from '../DocViewHeaderButton/DocViewHeaderButton';

import { useMutation } from '@tanstack/react-query';
import { uploadMultipleAttachments } from '../../api/box/files';
import requireFileSize from '../../utils/requireFileSize';

interface Props {
    onUploadSuccess?: (result) => void;
    onUploadError?: (error: any) => void;
    type?: 'default' | 'menuItem';
    fileId: string;
}

export function AddDocAttachmentButton({ onUploadSuccess, onUploadError, type, fileId }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const [resetHeuristicKey, setResetHeuristicKey] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buttonType] = useState(type ?? 'default');

    const hiddenFileInput = useRef<any>(null);

    const progressHandler = (progressEvent) => {
        if (!progressEvent?.total) throw new Error('No total progress. Check file size.');
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

        setProgress(() => progress);
    };

    const handleClick = () => {
        if (hiddenFileInput) hiddenFileInput?.current?.click();
    };

    const reset = () => {
        setProgress(0);
        setResetHeuristicKey((prev) => !prev);
        hiddenFileInput.current.value = '';
        setIsOpen(false);
    };

    const { mutateAsync, isLoading } = useMutation(
        ['boxUploadDocument'],
        (files: FileList) => uploadMultipleAttachments(fileId, files, progressHandler),
        {}
    );

    const handleFileChange = async (e: any) => {
        try {
            requireFileSize(e.target.files[0]);
            mutateAsync(e.target.files, {
                onSuccess: (result) => {
                    onUploadSuccess && onUploadSuccess(result);
                },
                onError: (error) => {
                    onUploadError && onUploadError(error);
                },
                onSettled: () => {
                    setResetHeuristicKey((prev) => !prev);
                    setIsOpen(false);
                    reset();
                },
            });
        } catch (error) {
            onUploadError && onUploadError(error);
        }
    };

    return (
        <>
            {buttonType === 'default' && (
                <DocViewHeaderButton disabled={isLoading} onClick={() => setIsOpen(true)}>
                    Add Annex
                </DocViewHeaderButton>
            )}

            {buttonType === 'menuItem' && (
                <MenuItem disabled={isLoading} onClick={() => setIsOpen(true)}>
                    Add Annex
                </MenuItem>
            )}

            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Add Annex</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {progress > 0 && progress <= 100 && (
                            <LinearProgress variant="determinate" value={progress} />
                        )}

                        {progress < 1 && (
                            <>
                                <span style={{ fontWeight: 'bold', color: 'red' }}>Warning:</span>{' '}
                                Attaching an <span style={{ fontWeight: 'bold' }}>annex</span> to a
                                document will cause the{' '}
                                <span style={{ fontWeight: 'bold' }}>Insights</span> and{' '}
                                <span style={{ fontWeight: 'bold' }}>Key Data</span> to be{' '}
                                <span style={{ fontWeight: 'bold' }}>reprocessed</span>! Any changes
                                you have made will be lost!
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {progress < 100 && (
                        <>
                            <Button variant="contained" onClick={() => handleClick()}>
                                Continue
                            </Button>
                            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                        </>
                    )}

                    {progress === 100 && (
                        <>
                            <Button
                                onClick={() => {
                                    setIsOpen(false);
                                    setProgress(0);
                                }}
                            >
                                Close
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <input
                type="file"
                key={resetHeuristicKey ? 'reset' : 'not-reset'}
                ref={hiddenFileInput}
                onInputCapture={handleFileChange}
                style={{ display: 'none' }}
                multiple
            />
        </>
    );
}
