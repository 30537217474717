import { Chip } from '@mui/material';
import Container from '../Container';

export default function EditedLabel() {
    return (
        <Container maxWidth={14.1875} leftOuterSpacing={0.5}>
            <Chip
                color="warning"
                sx={{
                    'height': '20px',
                    'fontSize': '0.7rem',
                    '& .MuiChip-label': {
                        paddingLeft: '6px',
                        paddingRight: '6px',
                        color: 'white',
                    },
                }}
                label="Edited"
            />
        </Container>
    );
}
